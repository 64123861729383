<template>
  <div id="app">
    <loading></loading>
    <snackbar></snackbar>
    <navbar></navbar>
    <slot></slot>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
// eslint-disable-next-line import/extensions
import loading from '@/components/TheLoader';
// eslint-disable-next-line import/extensions
import snackbar from '@/components/TheSnackbar';
// eslint-disable-next-line import/extensions
import navbar from '@/components/TheNavbar';

export default {
  name: 'edoc-perso',

  components: {
    loading,
    snackbar,
    navbar,
  },
  computed: {
    ...mapGetters({
      user: 'user/user',
    }),
  },
};
</script>
