<template>
  <div
    :class="classes"
    v-html="snackbar.message"
  ></div>
</template>

<script>
import { mapState } from 'vuex';

export default {

  name: 'TheSnackbar',

  computed: {
    ...mapState('application', {
      snackbar: state => state.snackbar,
    }),
    classes() {
      const cssClasses = {
        'ep-snackbar': true,
        visible: this.snackbar.active,
        'ep-snackbar__middle': this.snackbar.centered,
      };
      if (this.snackbar.type !== undefined
        && (this.snackbar.type === 'info'
          || this.snackbar.type === 'warning'
          || this.snackbar.type === 'success'
          || this.snackbar.type === 'error')) {
        cssClasses[`ep-snackbar--${this.snackbar.type}`] = true;
      }
      return cssClasses;
    },
  },
};
</script>
