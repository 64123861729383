<template>
  <el-navbar role="navigation" :isOpen="sideMenu.opened" @updateIsOpen="toggle">
    <!-- top part -->
    <!--   Logo   -->
    <router-link :to="{ name: logo.link,  }" :aria-label="$t('accessibility.sidebar.home')" :title="$t('accessibility.sidebar.home')"
      :class="['el-navbar__item el-navbar__item__logo', {'-MIPIH': isMIPIH}]" exact>
      <div v-if="!isMIPIH" :class="logo.icon"></div>
    </router-link>

    <!--   Items   -->
    <div v-for="item in top" :key="item.link">
      <router-link tabindex="0" :to="{ name: item.link, params: item.props }" class="el-navbar__item" :aria-label="$t('accessibility.sidebar.'+ item.icon)" :title="$t('accessibility.sidebar.'+ item.icon)">
        <div :class="item.icon"></div>
        <el-badge :inverted="true" class="el-badge--navbar" :notif="item.notification" />
      </router-link>
    </div>

    <!-- white space between -->
    <el-navbar-spacer></el-navbar-spacer>

    <!-- bottom part -->
    <!--   Items   -->
    <div v-for="item in bottom" :key="item.link">
      <!--   eslint-disable-next-line max-len   -->
     <a v-if="item.externalLink" :href="item.externalLink" class="el-navbar__item" :target="item.target" tabindex="0"  :aria-label="$t('accessibility.sidebar.'+ item.icon)" :aria-title="$t('accessibility.sidebar.'+ item.icon)">
        <div :class="item.icon"></div>
        <el-badge :inverted="true" class="el-badge--navbar" :notif="item.notification" />
      </a>

    <!--   eslint-disable-next-line max-len   -->
      <router-link v-else :to="{ name: item.link }"  class="el-navbar__item" :target="item.target" tabindex="0"  :aria-label="$t('accessibility.sidebar.'+ item.icon)" :title="$t('accessibility.sidebar.'+ item.icon)">
        <div :class="item.icon"></div>
        <el-badge :inverted="true" class="el-badge--navbar" :notif="item.notification" />
      </router-link>
    </div>
  </el-navbar>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import auth from '../utils/auth';

export default {

  name: 'TheNavbar',
  data() {
    return {
      logo: { link: 'home.index', icon: 'icon-edocperso' },
      top: [
        {
          link: 'folders.index',
          // props: { folderId: 'notClassified' },
          icon: 'icon-document',
          notification: 0,
        },
        {
          link: 'sharings.index',
          icon: 'icon-share',
        },
        {
          link: 'collectors',
          icon: 'icon-collecteur',
          notification: 0,
        },
      ],
      bottom: [
        {
          link: 'profile.index',
          icon: 'icon-espace_perso',
          notification: 0,
        },
        { link: 'logout', icon: 'icon-loggout', notification: 0 },
      ],
    };
  },
  watch: {
    usageDatas() {
      this.setNotif();
    },
  },

  computed: {
    ...mapState({
      sideMenu: state => state.application.sideMenu,
      isMIPIH: state => state.auth.isMIPIH,
    }),
    ...mapGetters({
      usageDatas: 'user/usageDatas',
    }),
  },

  methods: {
    ...mapActions({
      getUsageDatas: 'user/getUsageDatas',
    }),
    toggle() {
      this.$store.dispatch('application/toggleSideMenu');
    },
    setNotif() {
      if (this.usageDatas) {
        this.top[0].notification = this.usageDatas.nbNewDocs;
      }
    },
  },
  mounted() {
    if (this.usageDatas) this.setNotif();
    auth.getConf().then((response) => {
      if (response.CONF.MIPIH) {
        this.bottom[0].externalLink = response.CONF.supportURL;
      }
    });
  },
};
</script>
