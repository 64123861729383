<template>
  <div class="ep-loader" v-if="loading">
    <span class="ep-loader--dot"></span>
    <span class="ep-loader--dot"></span>
    <span class="ep-loader--dot"></span>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {

  name: 'TheLoader',

  computed: {
    ...mapState('application', {
      loading: state => state.loading.display,
    }),
  },
};
</script>
